import {Client} from '../../../shared/utilities/type-helpers';
const client: Client = {clientType: 't1'};

export const environment = {
  production: true,
  firebaseConfig: {
    apiKey: 'AIzaSyDO2BpUP8pE6opSytiZQC9vW4C9iic-l_8',
    authDomain: 't1paginas-devs.firebaseapp.com',
    projectId: 't1paginas-devs',
    storageBucket: 't1paginas-devs.firebasestorage.app',
    messagingSenderId: '988203945623',
    appId: '1:988203945623:web:4af533fcb29d8f0b23e3d2',
    measurementId: 'G-VCC5ZRKK82',
  },
  useEmulators: false,
  maxUplodedImageSize: 5000001,
  allowImageExt: '.jpg,.png,.jpeg',
  featureFlags: {longPolling: true},
  client,
};
